@import 'custom/load-awesome';

// Here you can add other styles
.sidebar {
  background: #fff;
  .nav-title {
    color: #73818f;
  }
  .nav-link {
    color: #fc6944;
    .nav-icon {
      color: #fc6944;
    }
    &.active {
      color: #fff;
      background: #fc6944;
      .nav-icon {
        color: #fff;
      }
    }
    &:hover {
      color: #fff;
      background: #fc6944;
      .nav-icon {
        color: #fff;
      }
    }
  }
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar {
    .nav-item:hover > .nav-link {
      background-color: #fc6944;
    }
    .sidebar-minimizer {
      background-color: #cb5235;
    }
  }
}

.fmt-card__dropdown-togglor:focus,
.fmt-card__dropdown-togglor:active,
.fmt-card__dropdown-togglor:active:focus {
  box-shadow: none !important;
}

.fmt-card__dropdown {
  top: -400px;
}

*[dir='rtl'] .dropdown-menu {
  right: auto;
  text-align: right;
}

.table-bouncer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;
}

.dotted-dropdown-togglor:focus,
.dotted-dropdown-togglor:active,
.dotted-dropdown-togglor:active:focus {
  box-shadow: none !important;
}

*[dir='rtl'] .dropdown-menu {
  right: auto !important;
}

.rounded {
  border-radius: 0.7rem !important;
}

/* Loader with logo */

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100;
  justify-content: center;
  top: 0;
  right: 0;
  border-radius: 10px;
}

.loading-content {
  position: absolute;
  // top: 15vh;
}

.loading-image {
  position: absolute;
  width: 80%;
  height: 60%;
  top: 13%;
  left: 9%;
}

.spinner-text {
  text-align: center;
  font-size: 16px;
  color: #fc6944;
  position: absolute;
  width: 100%;
}

.spin {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 3px solid #fc6944;
  border-radius: 100%;
  animation: spin 0.75s infinite linear;
}

.spin::before,
.spin::after {
  left: -2px;
  top: -2px;
  display: none;
  position: absolute;
  content: '';
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner,
.spinner::before,
.spinner::after {
  display: inline-block;
  border-color: transparent;
  border-top-color: #fc6944;
  animation-duration: 1.2s;
}
.spinner::before {
  transform: rotate(120deg);
}
.spinner::after {
  transform: rotate(240deg);
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 10px !important;
}

.Select--multi .Select-multi-value-wrapper {
  padding-right: 6px;
}

.rate-color {
  color: #ee763f;
}

.pointer {
  cursor: pointer;
}

.loading {
  /** https://github.com/webpack/webpack/issues/2771#issuecomment-233766239 **/
  background: url('/assets/img/brand/loading.svg') no-repeat center;
  padding: 50px 0;
  width: 100%;
  top: 0;
  left: 0;
}
.loading.absolute {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}
.loading.error {
  background: none;
  padding: 50px 20px;
  height: auto;
}
.loading.top {
  background-position-x: center;
  background-position-y: 100px;
}
.is-invalid .Select-control {
  border-color: $danger;
}

*[dir='rtl'] .custom-control-label::after,
*[dir='rtl'] .custom-control-label::before {
  margin-right: -20px !important;
}
